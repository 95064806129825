.main_index_container{
    position: relative;
    height: 100%;
    width: 100%;
    background: white;
}

.exercise_template_thumbnails_container {
    position: relative;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 2%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.exercise_template_thumbnail {
    position: relative;
    background-color: white;
    border-radius: 5px;
    width: 95%;
    height: auto;
    margin-right: 5%;
    box-sizing: border-box;
    margin: 2%;
    cursor: pointer;
    box-shadow: #5090F050 0px 0px 20px 0.5px;
    background-color: #FAFAFA;

}

.exercise_template_thumbnail_name {
    color:black;
}

.exercise_template_thumbnail_image img {
    width:100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px 0px 5px 5px;
}