/* Ordenador */

@media only screen and (min-width: 992px) {
    .campus {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 5;
    }

    .campus_menu {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
    }

    .campus_banner_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .campus_banner {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .campus_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: rgba(41, 47, 76, 1);
    }


    .campus_close_button {
        position: absolute;
        width: 3%;
        height: 6%;
        right: 4%;
        top: 12.5%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .campus_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .campus_main {
        padding-top: 1.5%;
        position: relative;
        width: 100%;
        height: 96%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 48%;
        overflow-y: scroll;
    }

    .campus_main_scenario {
        position: relative;
        width: 95%;
        height: 85%;
        padding: "0.5em";
        display: grid;
        grid-template-rows: 40% 30% 30%;
        background-color: #ffffff;
        border-radius: 5px;
        margin-left: 2.5%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .campus_main_scenario_banner {
        position: relative;
    }

    .campus_main_scenario_mid {
        position: relative;
        width: 90%;
        height: 100%;
        margin-left: 5%;
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    .campus_main_scenario_mid_left {
        position: relative;
        width: 100%;
        height: 80%;
        display: grid;
        grid-template-rows: 60% 40%;
    }

    .campus_main_scenario_mid_left_bottom {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .campus_main_scenario_mid_right {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .campus_main_scenario_bot {
        position: relative;
        width: 90%;
        height: 100%;
        margin-left: 5%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .campus_main_scenario_bot_images {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
    }

    .campus_main_scenario_bot_images_image {
        position: relative;
        width: 80%;
        height: 100%;
    }

    .campus_main_scenario_bot_points {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .campus_main_scenario_bot_date {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 10% 90%;
    }

    .course_tag_background {
        position: relative;
        width: 100%;
        height: 35%;
        padding: 1.5% 1.5%;
        border-radius: 100px;
    }

    .campus_subject_scenarios {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: #ffffff;
        display: grid;
        grid-template-rows: 12vw auto 1fr;
    }

    .campus_subject_content_up {
        position: relative;
        width: 95%;
        height: 100%;
        margin-left: 2.5%;
        display: grid;
        grid-template-columns: 50% 25% 25%;
    }

    .campus_subject_content_up_time {
        position: relative;
        width: 90%;
        height: 100%;
        margin-left: 5%;
        display: grid;
        grid-template-columns: 8% 90%;
    }

    .campus_subject_scenarios_loading {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .campus_subjects {
        position: absolute;
        width: 90%;
        left: 5%;
        display: grid;
        overflow-y: auto;
    }

    .campus_filters {
        position: absolute;
        width: 90%;
        height: 5%;
        left: 5%;
        top: 21.5%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .campus_filters_filter {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .campus_filters_sorting {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .banner {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .campus_subject_content_body {
        padding-top: 1.5%;
        position: relative;
        width: 95%;
        margin-left: 2.5%;
        height: 92%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-row-gap: 20px;
        overflow-y: scroll;
    }


    .campus_subject_content_1 {
        padding-top: 1.5%;
        position: relative;
        width: 100%;
        height: 94.75%;
        border-radius: 0px 0px 5px 5px;
        background-color: #F9DFC0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 33%;
        overflow-y: scroll;
    }

    .campus_subject_content_2 {
        padding-top: 1.5%;
        position: relative;
        width: 100%;
        height: 94.75%;
        border-radius: 0px 0px 5px 5px;
        background-color: #FCEAAF;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 33%;
        overflow-y: scroll;
    }

    .campus_subject_content_3 {
        padding-top: 1.5%;
        position: relative;
        width: 100%;
        height: 94.75%;
        border-radius: 0px 0px 5px 5px;
        background-color: #DDF9C0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 33%;
        overflow-y: scroll;
    }

    .campus_subject_content_4 {
        padding-top: 1.5%;
        position: relative;
        width: 100%;
        height: 94.75%;
        border-radius: 0px 0px 5px 5px;
        background-color: #C0DBF9;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 33%;
        overflow-y: scroll;
    }

    .campus_subject_content_5 {
        padding-top: 1.5%;
        position: relative;
        width: 100%;
        height: 94.75%;
        border-radius: 0px 0px 5px 5px;
        background-color: #E3C0F9;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 33%;
        overflow-y: scroll;
    }

    .campus_subject_content_6 {
        padding-top: 1.5%;
        position: relative;
        width: 100%;
        height: 94.75%;
        border-radius: 0px 0px 5px 5px;
        background-color: #F9C0F0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 33%;
        overflow-y: scroll;
    }





    /* for demo */
    /* body{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        min-height:100vh;
        padding:1rem 1rem 2rem 1rem;
        box-sizing:border-box;
        overflow:hidden;
      } */

    .title {
        color: var(--color-crayon);
        font-size: 1.5rem;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .campus_subject_subtopic {
        position: relative;
        width: 95%;
        height: 90%;
        /* margin: 5%; */
        display: grid;
        grid-template-columns: 20% 80%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .campus_subject_subtopic_content {
        position: relative;
        width: 95%;
        height: 100%;
        padding-left: 3%;
        border-radius: 5px;
        background-color: #ffffff;
        display: grid;
        grid-template-rows: 35% 40% 25%;
    }

    .campus_subject_subtopic_content_top {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .campus_subject_subtopic_content_mid {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .campus_subject_subtopic_content_bot {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 40% 60%;
    }

    .campus_subject_subtopic_content_bot_time {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 10% 90%;
    }

    .campus_exercise_background {
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 1.5% 1.5%;
        border-radius: 0px 0px 5px 5px;
    }

    .campus_exercise {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 12vw auto;
    }

    .campus_exercise_top {
        position: relative;
        /* width: 90%; */
        height: 100%;
        margin-left: 0%;
        width: 100%;
    }

    .campus_exercise_bottom {
        position: relative;
        width: 90%;
        height: 100%;
        display: grid;
        margin-left: 5%;
        grid-template-rows: 25% 35% 30%;
    }

    .campus_exercise_bottom_title {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 52% 2% 46%;
    }

    .campus_exercise_bottom_stuff {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr;
    }

    .campus_exercise_bottom_stuff_dates {
        position: relative;
        width: 100%;
        height: 80%;
        margin-top: 5%;
        display: grid;
        grid-template-rows: 1fr 1fr;
    }

    .campus_exercise_bottom_stuff_dates_time {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 10% 90%;
    }

    .campus_exercise_bottom_stuff_SDAs {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .campus_exercise_bottom_stuff_pilotes {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        text-align: center;
        flex-direction: column;
    }


    .campus_exercise_research {
        position: relative;
        width: 80%;
        height: 80%;
        margin-top: 2%;
        display: grid;
        grid-template-rows: 30% 60% 10%;
    }

    .campus_exercise_research_button {
        position: relative;
        width: 90%;
        height: 40%;
        border-radius: 4px;
    }

    .campus_exercise_research_bar_container {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
    }

    .campus_exercise_research_bar_line {
        position: absolute;
        height: 50%;
        width: 100%;
        top: 0%;
        border-radius: 25px;
        background-color: #D9D9D9;
    }

    .campus_exercise_research_bar_progress {
        position: absolute;
        height: 50%;
        top: 0%;
        border-radius: 25px;
    }

    .campus_exercise_experiment {
        position: relative;
        width: 80%;
        height: 80%;
        margin-top: 2%;
        display: grid;
        grid-template-rows: 30% 60% 10%;
    }

    .campus_exercise_experiment_progress {
        position: relative;
        width: 90%;
        left: 5%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    .campus_exercise_experiment_progress_bar {
        flex: 1;
        height: 50%;
        top: 0%;
        border-radius: 25px;
    }

    .campus_points {
        position: absolute;
        height: 18%;
        width: 94%;
        left: 3%;
        top: 80%;
        background-color: #ffffff;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 30% 70%;
    }

    .campus_points_interior {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: red;
    }

    .campus_points_top {
        position: relative;
        height: 100%;
        width: 96%;
        left: 2%;
        border-bottom: 1px solid #000000;
        display: grid;
        grid-template-columns: 25% 75%;
    }

    .campus_points_top_right {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    }

    .campus_points_subject_point {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 35% 5% 60%;
    }

    .campus_points_subject_circle {
        position: relative;
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }

    .campus_points_bottom {
        position: relative;
        height: 100%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-rows: repeat(2, 50%);
    }

    .campus_points_bottom_row {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 25%);
    }

    .campus_points_bottom_row_2 {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 25%);
    }

    .campus_points_bottom_element {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 10% 2% 88%;
    }

    .campus_points_bottom_element_2 {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 10% 2% 88%;
    }

    .campus_subtopic_banner {
        box-sizing: border-box;
        height: 96%;
        padding: 2%;
        position: relative;
        background-color: #ffffff;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .campus {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 5;
    }

    .campus_menu {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
    }

    .campus_banner_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .campus_banner {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .campus_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(315deg, rgba(41, 47, 76, 1) 0%, rgba(41, 47, 76, 0.7) 100%);
    }

    .campus_close_button {
        position: absolute;
        width: 3%;
        height: 6%;
        right: 4%;
        top: 10%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .campus_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .campus_subjects {
        position: absolute;
        width: 94%;
        height: 60%;
        left: 3%;
        top: 18%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .campus_topic_background {
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 4% 10%;
    }

    .campus_topic {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 25% 15% 20% 10% 15% 15%;
    }


    .banner {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .campus_topic_show_levels_button {
        position: relative;
        width: 40%;
        height: 100%;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .campus_topic_points_title {
        position: relative;
        width: 60%;
        height: 100%;
        margin-left: 20%;
    }

    .campus_topic_points {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .campus_topic_point {
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        font-size: 0.7em;
        color: white;
    }

    .campus_subtopics {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .campus_subtopics_title {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .campus_subtopics_content {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        overflow-y: scroll;
    }

    .campus_subtopic_background {
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 2.5% 2.5%;
    }

    .campus_subtopic {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 20% 80%;
    }

    .campus_subtopic_top {
        position: relative;
        width: 90%;
        height: 100%;
        margin-left: 5%;
    }

    .campus_subtopic_bottom {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
    }

    .campus_subtopic_research {
        position: relative;
        width: 80%;
        height: 80%;
        margin-top: 5%;
        margin-left: 10%;
        border-radius: 10px;
        display: grid;
        grid-template-rows: 30% 60% 10%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .campus_subtopic_research_button {
        position: relative;
        width: 50%;
        height: 40%;
        border-radius: 10px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .campus_subtopic_research_bar {
        position: relative;
        height: 100%;
        border-bottom-left-radius: 10px;
    }

    .campus_subtopic_experiment {
        position: relative;
        width: 80%;
        height: 80%;
        margin-top: 5%;
        margin-left: 10%;
        border-radius: 10px;
        display: grid;
        grid-template-rows: 30% 70%;
    }

    .campus_subtopic_experiment_center {
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 1% 10%;
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .campus_subtopic_experiment_button {
        position: relative;
        width: 50%;
        height: 70%;
        top: 15%;
        left: 13%;
        border-radius: 10px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .campus_points {
        position: absolute;
        height: 18%;
        width: 94%;
        left: 3%;
        top: 80%;
        background-color: #ffffff;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 30% 70%;
    }

    .campus_points_interior {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: red;
    }

    .campus_points_top {
        position: relative;
        height: 100%;
        width: 96%;
        left: 2%;
        border-bottom: 1px solid #000000;
    }

    .campus_points_subject_point {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .campus_points_subject_circle {
        position: relative;
        height: 15px;
        width: 15px;
        min-width: 15px;
        min-height: 15px;
        border-radius: 50%;
    }

    .campus_points_bottom {
        position: relative;
        height: 100%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-rows: repeat(2, 50%);
    }

    .campus_points_bottom_row {
        position: relative;
        height: 100%;
        width: 95%;
        margin-left: 10%;
        display: grid;
        grid-template-columns: repeat(4, 25%);
    }

    .campus_points_bottom_row_2 {
        position: relative;
        height: 100%;
        width: 75%;
        margin-left: 10%;
        display: grid;
        grid-template-columns: repeat(3, 33.333%);
    }

    .campus_points_bottom_element {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 10% 90%;
    }

    .campus_points_bottom_element_2 {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }
}