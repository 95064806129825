/* Ordenador */

@media only screen and (min-width: 992px) {
    .edit_choose_one_ {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    .edit_choose_one_professor {
        position: absolute;
        height: 20%;
        width: 20%;
        bottom: 0;
        left: 5%;
    }

    .edit_choose_one_text_box {
        position: absolute;
        height: 48%;
        width: 28%;
        top: 15%;
        left: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0%;
        z-index: 1;
    }

    .edit_choose_one_arrows {
        position: absolute;
        height: 10%;
        width: 10%;
        bottom: 1.5%;
        left: 40%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_choose_one_confirm {
        position: absolute;
        height: 9%;
        width: 12%;
        bottom: 2%;
        left: 55%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 1;
    }

    .edit_choose_one_help_button {
        position: absolute;
        height: 5%;
        width: 18%;
        display: grid;
        grid-template-columns: 5fr 1fr;
        bottom: 1%;
        right: 5%;
        z-index: 6;
    }

    .write_help_text_button {
        position: relative;
        height: 90%;
        width: 90%;
        background-color: white;
        color: #575757;
        border-radius: 5px;
    }

    .edit_choose_one_help_menu {
        position: absolute;
        height: 50%;
        width: 30%;
        top: 20%;
        left: 35%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 7;
    }

    .edit_choose_one_image {
        position: absolute;
        height: 50%;
        width: 35%;
        top: 12%;
        right: 15%;
        z-index: 1;
    }

    .edit_choose_one_options {
        position: absolute;
        height: 20%;
        width: 60%;
        top: 68%;
        left: 35%;
        display: grid;
    }

    .edit_choose_one_option_container {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        display: grid;
        grid-template-rows: 9fr 6fr;
    }

    .edit_choose_one_option {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #E8E8E8;
        border-radius: 50px;
        cursor: pointer;
    }

    .edit_choose_one_option_pressed {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #2F80ED;
        border-radius: 50px;
        cursor: pointer;
    }

    .edit_choose_one_option_bottom {
        position: relative;
        margin-top: 5%;
        height: 95%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .edit_choose_one_option_bottom_correct {
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;
    }

    .edit_choose_one_option_bottom_delete {
        position: relative;
        height: 2.8vw;
        width: 2.8vw;
        margin-right: 1%;
        border: 2px solid white;
        background-color: #F0684F;
        border-radius: 50%;
        cursor: pointer;
    }

    .edit_choose_one_option_checkbox {
        position: relative;
        width: 2.5vw;
        height: 2.5vw;
        background-color: #5090F025;
        border-radius: 50%;
        border: 2px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .edit_choose_one_option_checkbox_marked {
        position: relative;
        width: 2.5vw;
        height: 2.5vw;
        background-color: #6EE6A7;
        border-radius: 50%;
        border: 2px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .edit_choose_one_add_option {
        position: relative;
        height: 60%;
        width: 10vw;
        left: 5%;
        background-color: #E8E8E8;
        border-radius: 50px;
        cursor: pointer;
    }
}



/* Tablet */

@media only screen and (max-width: 991px) {
    .edit_choose_one_ {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .edit_choose_one_text_box {
        position: absolute;
        height: 18%;
        width: 90%;
        top: 2%;
        left: 5%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 1;
    }

    .edit_choose_one_arrows {
        position: absolute;
        height: 12%;
        width: 20%;
        bottom: 0;
        left: 50%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_choose_one_confirm {
        position: absolute;
        height: 7%;
        width: 20%;
        bottom: 2.5%;
        left: 5%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 1;
    }

    .edit_choose_one_help_button {
        position: absolute;
        height: 12%;
        width: 10%;
        bottom: 0;
        right: 2%;
        z-index: 1;
    }

    .edit_choose_one_help_menu {
        position: absolute;
        height: 50%;
        width: 60%;
        top: 20%;
        left: 20%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 2;
    }

    .edit_choose_one_image {
        position: absolute;
        height: 50%;
        max-width: 40%;
        top: 12%;
        right: 15%;
        z-index: 1;
    }

    .edit_choose_one_options {
        position: absolute;
        height: 6%;
        width: 90%;
        top: 75.5%;
        left: 5%;
        display: grid;
        z-index: 1;
    }

    .edit_choose_one_option {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #E8E8E8;
        border-radius: 30px;
        cursor: pointer;
        z-index: 1;
    }

    .edit_choose_one_option_pressed {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #2F80ED;
        border-radius: 30px;
        cursor: pointer;
        z-index: 1;
    }
}