/* Ordenador */

@media only screen and (min-width: 992px) {
    .edit_drag_drop_two_lists {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    .edit_drag_drop_two_lists_professor {
        position: absolute;
        height: 20%;
        width: 20%;
        bottom: 0;
        left: 5%;
    }

    .edit_drag_drop_two_lists_text_box {
        position: absolute;
        height: 48%;
        width: 28%;
        top: 15%;
        left: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0%;
        z-index: 6;
    }

    .edit_drag_drop_two_lists_arrows {
        position: absolute;
        height: 10%;
        width: 10%;
        bottom: 1.5%;
        left: 40%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 6;
    }

    .edit_drag_drop_two_lists_confirm {
        position: absolute;
        height: 9%;
        width: 12%;
        bottom: 2%;
        left: 55%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 6;
    }

    .edit_drag_drop_two_lists_help_button {
        position: absolute;
        height: 5%;
        width: 18%;
        display: grid;
        grid-template-columns: 5fr 1fr;
        bottom: 1%;
        right: 5%;
        z-index: 6;
    }

    .write_help_text_button {
        position: relative;
        height: 90%;
        width: 90%;
        background-color: white;
        color: #575757;
        font-weight: bold;
        border-radius: 5px;
    }

    .edit_drag_drop_two_lists_help_menu {
        position: absolute;
        height: 50%;
        width: 30%;
        top: 20%;
        left: 35%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 7;
    }

    .edit_drag_drop_two_lists_background_edit {
        position: absolute;
        height: 70%;
        width: 60%;
        top: 15%;
        right: 2%;
        z-index: 6;
    }

    .edit_drag_drop_two_lists_top {
        position: relative;
        margin-top: 0%;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .edit_drag_drop_two_lists_droppable_0 {
        position: absolute;
        height: 100%;
        width: 48%;
        display: grid;
        grid-template-rows: 4fr 22fr;
        background-color: #6EE6A7;
        border-radius: 10px;
    }

    .edit_drag_drop_two_lists_droppable_1 {
        position: absolute;
        height: 100%;
        width: 48%;
        left: 54%;
        display: grid;
        grid-template-rows: 4fr 22fr;
        background-color: #F0684F;
        border-radius: 10px;
    }

    .edit_drag_drop_two_lists_label {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .edit_drag_drop_two_lists_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
    }

    .edit_drag_drop_two_lists_draggable_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
    }

    .edit_drag_drop_two_lists_draggable {
        position: relative;
        width: 90%;
        height: 60%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }

    .edit_drag_drop_two_lists_add_option {
        position: relative;
        width: 90%;
        height: 60%;
        margin-left: 5%;
        margin-top: 1em;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
    }

    .edit_drag_drop_two_lists_draggable_bottom {
        position: relative;
        width: 95%;
        height: 30%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }

    .edit_drag_drop_two_lists_option_delete {
        position: absolute;
        right: 0;
        top: 10%;
        height: 4vh;
        width: 4vh;
        margin-right: 3%;
        background-color: #F0684F;
        border-radius: 50%;
        cursor: pointer;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .edit_drag_drop_two_lists {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .edit_drag_drop_two_lists_text_box {
        position: absolute;
        height: 20%;
        width: 90%;
        top: 7%;
        left: 5%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 6;
    }

    .edit_drag_drop_two_lists_arrows {
        position: absolute;
        height: 12%;
        width: 20%;
        bottom: 0;
        left: 50%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 6;
    }

    .edit_drag_drop_two_lists_confirm {
        position: absolute;
        height: 7%;
        width: 20%;
        bottom: 2.5%;
        left: 5%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 6;
    }

    .edit_drag_drop_two_lists_help_button {
        position: absolute;
        height: 12%;
        width: 10%;
        bottom: 0;
        right: 2%;
        z-index: 6;
    }

    .edit_drag_drop_two_lists_help_menu {
        position: absolute;
        height: 50%;
        width: 60%;
        top: 20%;
        left: 20%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 7;
    }

    .edit_drag_drop_two_lists_background {
        position: absolute;
        height: 58%;
        width: 88.5%;
        top: 29%;
        left: 5%;
        display: grid;
        grid-template-rows: 70% 30%;
        z-index: 6;
    }

    .edit_drag_drop_two_lists_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .edit_drag_drop_two_lists_droppable_0 {
        position: absolute;
        height: 100%;
        width: 48%;
        background-color: #6EE6A7;
        border-radius: 10px;
    }

    .edit_drag_drop_two_lists_droppable_1 {
        position: absolute;
        height: 100%;
        width: 48%;
        left: 54%;
        background-color: #F0684F;
        border-radius: 10px;
    }

    .edit_drag_drop_two_lists_label {
        position: relative;
        height: 12%;
        width: 100%;
    }

    .edit_drag_drop_two_lists_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
    }

    .edit_drag_drop_two_lists_draggable {
        position: relative;
        width: 90%;
        height: 12.5%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }

    .edit_drag_drop_two_lists_draggable_bottom {
        position: relative;
        width: 95%;
        height: 30%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }
}