.teacher_tutorial_2_2_background_black_bot {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 2%;
    top: 98%;
    left: 0;
}

.teacher_tutorial_2_2_background_black_top {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 85%;
    top: 0;
    left: 0;

}

.teacher_tutorial_2_2_background_black_variable_left {
    background-color: #00000088;
    position: absolute;
    height: 13%;
    top: 85%;
    left: 0;
}

.teacher_tutorial_2_2_background_black_variable_right {
    background-color: #00000088;
    position: absolute;
    height: 13%;
    top: 85%;
    right: 0;
}

.teacher_tutorial_2_3_background_black_bot {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 90%;
    top: 10%;
    left: 0;
}

.teacher_tutorial_2_3_background_black_top {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 2%;
    top: 0;
    left: 0;
}

.teacher_tutorial_2_3_background_black_variable_left {
    background-color: #00000088;
    position: absolute;
    height: 8%;
    top: 2%;
    left: 0;
}

.teacher_tutorial_2_3_background_black_variable_right {
    background-color: #00000088;
    position: absolute;
    height: 8%;
    top: 2%;
    right: 0;
}

.teacher_tutorial_2_2_container {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 40%;
    margin-left: 15%;
    margin-top: 15%;
    height: fit-content;

}

.teacher_tutorial_2_1_container {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 38%;
    margin-left: 15%;
    margin-bottom: 5%;
    height: fit-content;
    border-color: #eeeeee;
    border-width: 1px;
}

.teacher_tutorial_2_3_container {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 38%;
    margin-left: 35%;
    margin-bottom: 30%;
    height: fit-content;

}