.professor_scenario_menu {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1% 2% 2% 2%;

    display: grid;
    grid-template-rows: 10% 80% 10%;
}

.professor_scenario_menu_button {
    position: relative;
    width: 10vw;
    height: 100%;
    box-sizing: border-box;
    padding: 1% 1% 1% 1%;
    border-radius: 57px;
}

.professor_scenario_menu_bottom {
    position: relative;
    width: 92.5%;
    height: 60%;
    top: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;

}

.professor_scenario_menu_breadcrumbs {
    position: relative;
    width: fit-content;
    height: 8vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.professor_scenario_menu_breadcrumb_active {
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
}

.professor_scenario_menu_breadcrumb_inactive {
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    border: 3px solid #C8C8C8;
}

.professor_scenario_menu_top {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    border-bottom: #5090F0 solid 1px;

}

.professor_scenario_menu_bundle_data {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    border-bottom: #5090F0 solid 1px;
}

.professor_scenario_menu_top_title {
    position: relative;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1vh;
    align-items: center;
}

.professor_scenario_menu_top_title_pages {
    position: relative;
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.professor_scenario_menu_edit_button {

    position: absolute;
    width: 4.5vh;
    height: 4.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    top: 20%;
    right: 5%;
    border-radius: 50%;
    background-color: #FFD250;
    box-shadow: 0px 0px 10px 0px #FFD250;
    box-sizing: border-box;
    padding: 0.4%;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.professor_scenario_menu_main {
    position: relative;
    width: 100%;
    height: 100%;
}

.professor_scenario_menu_main_page_1 {
    position: relative;
    width: 100%;

    border-radius: 10px;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2%;
}

.professor_scenario_menu_main_page_1_top {
    position: relative;
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

.professor_scenario_menu_main_page_1_top_banner {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-right: 2%;
    padding-top: 1%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

}

.professor_scenario_menu_main_page_1_top_badges {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    height: 50%;
    width: 50%;
}

.professor_scenario_menu_main_page_1_top_course {
    position: relative;
    border-radius: 50px;
    padding-left: 2%;
    padding-right: 2%;
    margin-left: 2%;
}

.professor_scenario_menu_main_page_1_top_regulation {
    position: relative;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 50px;
    background: #5090F0;
    margin-left: 2%;
}

.professor_scenario_menu_main_page_1_top_lang {
    position: relative;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 50px;
    background: black;
    margin-left: 2%;
}

.professor_scenario_menu_main_page_1_middle {
    position: relative;
    width: 100%;
    height: 25%;
    box-sizing: border-box;
    padding: 1% 2% 1% 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.professor_scenario_menu_main_page_1_bottom {
    position: relative;
    height: 55%;
    width: 100%;
    box-sizing: border-box;
    padding: 1% 2% 1% 2%;
}

.professor_scenario_menu_main_page_1_bottom_grid {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 20% 80%;
}

.professor_scenario_menu_main_page_1_bottom_left {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;

}

.professor_scenario_menu_main_page_1_bottom_left_info {
    position: relative;
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.professor_scenario_menu_main_page_1_bottom_left_info_desc {
    position: relative;
    max-height: 10vh;
    width: 100%;
    max-width: 30vw;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal;
}

.professor_scenario_menu_main_page_1_bottom_right {
    position: relative;
    height: 100%;
    max-height: 15vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(4vh, auto);
    gap: 0.5rem;
    overflow-y: scroll;
    align-content: start;
    box-sizing: border-box;
    padding-right: 1%;
}

.professor_scenario_menu_main_page_1_know_how_badge {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0.5rem 0.5rem;
    margin-right: 0.5rem;
    background-color: #D9D9D980;
}

.professor_scenario_menu_activities {
    position: relative;
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #E2EEFF;
    gap: 8%;
}

.professor_scenario_menu_activities_numbers {
    position: relative;
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 1%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #E2EEFF;
    gap: 8%;
}

.professor_scenario_menu_activities_number_container {
    position: relative;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.professor_scenario_menu_main_page_3 {
    position: fixed;
    width: 81.5%;
    height: 70%;
    top: 15%;
    border-radius: 10px;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2%;
}

.professor_scenario_menu_main_page_3_top {
    position: relative;
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: repeat(4, 1fr); */
    grid-auto-rows: minmax(auto, auto);
    gap: 0.5rem;
    overflow-y: scroll;
    align-content: start;
    box-sizing: border-box;
    padding-right: 1%;
}

.professor_scenario_menu_main_page_3_top_badge {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0.5rem 0.5rem;
    margin-right: 0.5rem;

}

.professor_scenario_menu_main_page_3_bottom {
    position: relative;
    width: 100%;
    height: 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1%;
    /* margin-left: 2.5%; */


}

.professor_scenario_menu_main_page_3_bottom_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.professor_scenario_menu_main_page_3_bottom_spec_comp {
    position: relative;
    height: inherit;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    margin-bottom: 0.8em;
}

.professor_scenario_menu_main_page_3_bottom_key_comp {
    position: relative;
    height: inherit;
    width: 100%;
    display: grid;
    box-sizing: border-box;
}

.professor_scenario_menu_main_page_program {
    position: fixed;
    width: 81.5%;
    height: 70%;
    top: 15%;
    border-radius: 10px;
    background-color: #FAFAFA;
    display: grid;
    grid-template-columns: 45% 55%;
    box-sizing: border-box;
    padding: 3vh;
}

.professor_scenario_menu_main_page_program_left {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
    gap: 10%;
    box-sizing: border-box;
    padding: 1vh 3vh 1vh 0vh;
}

.professor_scenario_menu_main_page_program_left_hide_bundle {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8%;
}

.professor_scenario_menu_main_page_program_right {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #FFFFFF;
    gap: 2vh;
    box-sizing: border-box;
    padding: 1vh 3vh;
    overflow-y: scroll;
}

.professor_scenario_menu_main_page_program_groups {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.professor_scenario_menu_main_page_program_students_header {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1vh;
}

.professor_scenario_menu_main_page_program_students_header_data {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1vh;
}

.professor_scenario_menu_main_page_program_group_item {
    position: relative;
    display: grid;
    width: 100%;
    height: 5vh;
    align-items: center;
    grid-template-columns: 50% 5% 45%;
    white-space: nowrap;
}

.professor_scenario_menu_main_page_program_group_item_icon_name {
    position: relative;
    height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
}

.scenario_back_pop_up_container {
    position: relative;
    height: 40%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1%;
    background: #E2EEFF;
    border-radius: 10px;
}

.scenario_pop_up_main_container_grid {
    position: relative;
    height: 90%;
    width: 100%;
    box-sizing: border-box;
    padding: 2.5%;
}

.scenario_pop_up_main_content {
    position: relative;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1vh;
}

.scenario_pop_up_button {
    position: relative;
    height: 90%;
    width: 50%;
    background-color: #5090F0;
    border-radius: 25px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.scenario_menu_date_picker {
    height: 4vh;
    width: 100%;
    background-color: #EEEEEE;
    border-radius: 5px;
    font-family: 'Causten';
    text-align: center;
    color: black;
}

.scenario_back_duplicate_menu_container {
    position: relative;
    height: 35%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1%;
    background: #E2EEFF;
    border-radius: 10px;
}

.scenario_duplicate_menu_main_text {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.scenario_duplicate_menu_button_cancel {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #D9D9D9;
    border-radius: 25px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.scenario_duplicate_menu_button_duplicate {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #6EE6A7;
    border-radius: 25px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.scenario_duplicate_menu_buttons {
    position: absolute;
    width: 65%;
    height: 20%;
    bottom: 5%;
    left: 17.5%;
    display: grid;
    gap: 5vh;
    grid-template-columns: 1fr 1fr;
}

.professor_scenario_menu_bottom_center {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.professor_scenario_main_page_program_button_delete {
    position: relative;
    /* left: 5%; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 100%;
    box-sizing: border-box;
    padding: 1% 1% 1% 1%;
    border-radius: 57px;
    background-color: #FF8787;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}

.professor_scenario_main_page_program_button_save {
    position: relative;
    /* right: 8%; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 100%;
    box-sizing: border-box;
    padding: 1% 1% 1% 1%;
    border-radius: 57px;
    background-color: #6EE6A7;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}