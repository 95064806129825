/* Ordenador */

@media only screen and (min-width: 992px) {

    .professor_activities {
        position: relative;
        height: 100%;
        width: 94%;
        left: 3%;
        box-sizing: border-box;
        padding: 0.5% 0%;
    }

    .professor_activities_menu {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_activities_menu_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        padding-top: 0.5%;
        align-items: center;
        justify-content: space-between;
        z-index: 99;
    }

    .professor_activities_menu_top_new {
        position: relative;
        height: 70%;
        width: fit-content;
        display: flex;
        box-sizing: border-box;
        padding-top: 0.1em;
        padding-bottom: 0.1em;
        padding-left: 1em;
        padding-right: 1em;
        border-radius: 50px;
        background: #6EE6A7;
        box-shadow: 0px 0px 5px 0px rgba(110, 230, 167, 0.50);
    }

    .professor_activities_menu_top_calendar {
        position: relative;
        height: 70%;
        width: fit-content;
        display: flex;
        box-sizing: border-box;
        padding-top: 0.1em;
        padding-bottom: 0.1em;
        padding-left: 1em;
        padding-right: 1em;
        border-radius: 5px;
        background: #E2EEFF;
        text-decoration: underline solid #5090F0 1px;
    }

    .professor_activities_menu_top_dates_switch {
        position: relative;
        height: 80%;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 50%;
        background: #E2EEFF;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_activities_menu_top_toggle_switch {
        position: relative;
        height: fit-content;
        width: 8%;
        display: grid;
        grid-template-columns: 40% 20% 40%;
        border-radius: 500px;
        background: #E2EEFF;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_activities_menu_top_toggle_switch_button {
        position: relative;
        height: 100%;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 50%;
        background: #5090F0;
    }

    .professor_activities_menu_main {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1% 0%;

    }

    .professor_activities_menu_main_activity {
        position: relative;
        width: 98%;
        margin-left: 0%;
        display: grid;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
        grid-template-columns: 60% 40%;
        z-index: 8;
    }

    .professor_activities_menu_main_activity_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_activities_menu_main_activity_right {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_activities_menu_main_activity_left_name {
        position: relative;
        height: 100%;
        max-width: 50%;
        align-content: center;
    }

    .professor_activities_menu_main_activity_left_activities {
        position: relative;
        margin-left: 2%;
    }

    .professor_activities_menu_main_activity_left_course {
        position: relative;
        border-radius: 50px;
        padding-left: 1%;
        padding-right: 1%;
        margin-left: 2%;
    }

    .professor_activities_menu_main_activity_left_age {
        position: relative;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 50px;
        margin-left: 2%;
    }

    .professor_activities_menu_main_activity_left_lang {
        position: relative;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 50px;
        background: black;
        margin-left: 2%;
    }

    .professor_activities_menu_main_activity_right_type {
        position: relative;
        margin-right: 2%;
    }

    .professor_activities_menu_main_activity_right_dates {
        position: relative;
        margin-right: 4%;
    }

    .professor_activities_menu_main_activity_right_group {
        position: relative;
        margin-right: 2%;

    }

    .professor_activities_menu_main_activity_right_owner {
        position: relative;
        margin-right: 4%;
        display: flex;
    }

    .professor_activities_menu_top_filter {
        width: auto;
        height: 70%;
        /* margin-left: 25%; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5em;
    }


    .background-overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99;
    }

    .professor_activities_menu_tutorial {
        position: relative;
        /* height: 55%; */
        height: fit-content;
        width: 45%;
        border-radius: 10px;
        background-color: white;
        box-sizing: border-box;
        padding-top: 1%;
        padding-bottom: 1%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1vh;
        border-color: #5090F0;
        border-width: 1px;

    }

    .professor_activities_menu_video {
        position: relative;
        height: 18vh;
        aspect-ratio: 16 / 9;
        margin-left: 1%;
    }

    .professor_activities_menu_video_hover {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        border-radius: 5px;
    }

    .professor_activities_menu_tutorial_buttons {
        position: relative;
        height: 10vh;
        width: 80%;
        margin-top: 3%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .professor_activities_menu_tutorial_buttons_button {
        border-radius: 5px;
        height: 80%;
        width: 35%;
        border: none;
        cursor: url('http://api.elemer.es/cursorscaler/?size=24&type=pointer'), auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .professor_activities_menu_tutorial_buttons_button:nth-child(1) {
        background-color: #99c2ff;
    }

    .professor_activities_menu_tutorial_buttons_button:nth-child(2) {
        background-color: #6ee6a7;
    }

    .text_padded {
        padding-left: 5%;
        padding-right: 5%;
    }

    .professor_activities_menu_hide_bundle_container {
        position: relative;
        height: 39%;
        width: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1%;
        background: #E2EEFF;
        border-radius: 10px;
    }

    .professor_activities_menu_hide_bundle_container_grid {
        position: relative;
        height: 95%;
        width: 100%;
        box-sizing: border-box;
        padding: 2.5%;
    }

    .professor_activities_menu_hide_bundle_content {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 1vh;
        margin-top: 2%;
    }

    .professor_activities_menu_hide_bundle_buttons {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .professor_activities_menu_hide_bundle_button {
        position: relative;
        height: fit-content;
        width: fit-content;
        border-color: #5090F0;
        border-width: 1px;
        border-radius: 25px;
        box-sizing: border-box;
        padding: 2% 10% 2% 10%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }




}

/* Tablet */

@media only screen and (max-width: 991px) {

    .professor_activities_menu {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
        box-sizing: border-box;
        padding: 2%;
    }

}