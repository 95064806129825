.professor_exercise_editor {
    position: fixed;
    display: grid;
    top: 12.5%;
    height: 75%;
    width: 81.5%;
    grid-template-columns: 20% 2% 78%;
}

.professor_exercise_editor_navbar {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4%;
    align-items: center;
    overflow-y: scroll;
}

.professor_exercise_editor_navbar_list_container {
    position: relative;
    height: fit-content;
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 1%;
    background-color: #FAFAFA;
}

.professor_exercise_editor_navbar_list {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 3%;
}

.professor_exercise_editor_navbar_list_element {
    position: relative;
    width: 100%;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #8DBCFF;
}

.professor_exercise_editor_navbar_list_outer_dot {
    position: relative;
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.professor_exercise_editor_navbar_list_inner_dot_background {
    position: relative;
    width: 2vh;
    height: 2vh;
    border-radius: 50%;
    background-color: #FFFFFF;
}

.professor_exercise_editor_navbar_list_inner_dot {
    position: relative;
    width: 2vh;
    height: 2vh;
    border-radius: 50%;
}

.professor_exercise_editor_exercise {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow-y: scroll;
}

.professor_exercise_editor_add_level_button{
    position: absolute;
    height: 5%;
    width: 20%;
    left: 2%;
    top: 2%;
    border-radius: 5px;
    background-color:  #5090F0;
    box-shadow: 0px 0px 2px 1px #5090F0;
    cursor: pointer;
    z-index: 10;
  }

  .professor_exercise_editor_delete_button{
    position: absolute;
    height: 5%;
    width: 20%;
    right: 2%;
    top: 2%;
    border-radius: 5px;
    background-color:  #F0684F;
    box-shadow: 0px 0px 2px 1px #F0684F;
    cursor: pointer;
    z-index: 10;
  }

  .professor_exercise_editor_background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    right: 0%;
    object-fit: cover;
    border-radius: 10px;
  }