/* Ordenador */

@media only screen and (min-width: 992px) {
    .edit_drag_drop_options {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    .edit_drag_drop_options_professor {
        position: absolute;
        height: 20%;
        width: 20%;
        bottom: 0;
        left: 5%;
    }

    .edit_drag_drop_options_text_box {
        position: absolute;
        height: 48%;
        width: 28%;
        top: 15%;
        left: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0%;
        z-index: 1;
    }

    .edit_drag_drop_options_help_button {
        position: absolute;
        height: 5%;
        width: 18%;
        display: grid;
        grid-template-columns: 5fr 1fr;
        bottom: 1%;
        right: 5%;
        z-index: 6;
    }

    .edit_drag_drop_options_arrows {
        position: absolute;
        height: 10%;
        width: 10%;
        bottom: 1.5%;
        left: 40%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_drag_drop_options_confirm {
        position: absolute;
        height: 9%;
        width: 12%;
        bottom: 2%;
        left: 55%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 1;
    }

    .write_help_text_button {
        position: relative;
        height: 90%;
        width: 90%;
        background-color: white;
        color: #575757;
        font-weight: bold;
        border-radius: 5px;
    }

    .edit_drag_drop_options_help_menu {
        position: absolute;
        height: 50%;
        width: 30%;
        top: 20%;
        left: 35%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 2;
    }

    .edit_drag_drop_options_text_box_main {
        position: absolute;
        height: 48%;
        width: 60%;
        top: 15%;
        right: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0%;
        z-index: 1;
    }

    .edit_drag_drop_options_background {
        position: absolute;
        height: 22%;
        width: 60%;
        top: 65%;
        right: 2.5%;
        display: grid;
        z-index: 1;
    }

    .edit_drag_drop_options_background_grid {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .edit_drag_drop_options_background_column {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 96% 4%;
    }

    .edit_drag_drop_options_add_option {
        position: relative;
        height: 40%;
        top: 20%;
        width: 10vw;
        margin-left: 5%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
    }

    .edit_drag_drop_options_background_column_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 20% 50% 30%;
    }

    .edit_drag_drop_options_background_column_right {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .edit_drag_drop_options_background_column_right_line {
        position: relative;
        height: 100%;
        width: 50%;
        border-right: 2px dashed rgb(255, 255, 255);
    }

    .edit_drag_drop_options_droppable {
        position: absolute;
        height: 50%;
        width: 100%;
        top: 50%;
    }

    .edit_drag_drop_options_draggable {
        position: relative;
        width: 90%;
        height: 80%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }

    .edit_drag_drop_options_option_delete {
        position: relative;
        height: 5vh;
        width: 5vh;
        margin-right: 5%;
        background-color: #F0684F;
        border-radius: 50%;
        cursor: pointer;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .edit_drag_drop_options {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .edit_drag_drop_options_text_box {
        position: absolute;
        height: 20%;
        width: 90%;
        top: 7%;
        left: 5%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 1;
    }

    .edit_drag_drop_options_arrows {
        position: absolute;
        height: 12%;
        width: 20%;
        bottom: 0;
        left: 50%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_drag_drop_options_confirm {
        position: absolute;
        height: 7%;
        width: 20%;
        bottom: 2.5%;
        left: 5%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 1;
    }

    .edit_drag_drop_options_help_button {
        position: absolute;
        height: 12%;
        width: 10%;
        bottom: 0;
        right: 2%;
        z-index: 1;
    }

    .edit_drag_drop_options_help_menu {
        position: absolute;
        height: 50%;
        width: 60%;
        top: 20%;
        left: 20%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 2;
    }

    .edit_drag_drop_options_text_box_main {
        position: absolute;
        height: 35%;
        width: 90%;
        top: 30%;
        left: 5%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 1;
    }

    .edit_drag_drop_options_background {
        position: absolute;
        height: 15%;
        width: 90%;
        top: 70%;
        left: 5%;
        display: grid;
        z-index: 1;
    }

    .edit_drag_drop_options_background_grid {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .edit_drag_drop_options_background_column {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 90% 10%;
    }

    .edit_drag_drop_options_background_column_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 50% 50%;
    }

    .edit_drag_drop_options_background_column_right {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .edit_drag_drop_options_background_column_right_line {
        position: relative;
        height: 100%;
        width: 50%;
        border-right: 2px dashed rgb(255, 255, 255);
    }

    .edit_drag_drop_options_droppable {
        position: absolute;
        height: 50%;
        width: 100%;
        top: 50%;
    }

    .edit_drag_drop_options_draggable {
        position: relative;
        width: 90%;
        height: 80%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }
}