.professor_scenario_edit_menu {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1% 2% 2% 2%;

    display: grid;

}

.professor_scenario_edit_menu_button {
    position: relative;
    width: 10vw;
    height: 8vh;
    box-sizing: border-box;
    padding: 1% 1% 1% 1%;
    border-radius: 57px;
}

.professor_scenario_edit_menu_bottom {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2%;

}

.professor_scenario_edit_menu_breadcrumbs {
    position: relative;
    width: 8vw;
    height: 8vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.professor_scenario_edit_menu_breadcrumb_active {
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
}

.professor_scenario_edit_menu_breadcrumb_inactive {
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    border: 3px solid #C8C8C8;
}

.professor_scenario_edit_menu_top {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    border-bottom: #5090F0 solid 1px;

}

.professor_scenario_edit_menu_bundle_data {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    border-bottom: #5090F0 solid 1px;
}

.professor_scenario_edit_menu_top_title {
    position: relative;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1vh;
    align-items: center;
}

.professor_scenario_edit_menu_top_title_pages {
    position: relative;
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.professor_scenario_edit_menu_edit_button {

    position: absolute;
    width: 4.5vh;
    height: 4.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    top: 20%;
    right: 5%;
    border-radius: 50%;
    background-color: #FFD250;
    box-shadow: 0px 0px 10px 0px #FFD250;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.professor_scenario_edit_menu_main {
    position: relative;
    width: 100%;
    height: 100%;
}

.professor_scenario_edit_menu_main_page_1 {
    position: relative;
    width: 100%;

    border-radius: 10px;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2%;
}

.professor_scenario_edit_menu_main_page_1_top {
    position: relative;
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-top-left-radius: 10px;
}

.professor_scenario_edit_menu_main_page_1_top_banner{
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-right: 2%;
    padding-top: 1%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.professor_scenario_edit_menu_main_page_1_top_badges{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    width: 50%;

}

.professor_scenario_edit_menu_main_page_1_top_course {
    position: relative;
    border-radius: 50px;
    padding-left: 1%;
    padding-right: 1%;
    margin-left: 2%;
}

.professor_scenario_edit_menu_main_page_1_top_regulation {
    position: relative;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 50px;
    background: #5090F0;
    margin-left: 2%;
}

.professor_scenario_edit_menu_main_page_1_top_lang {
    position: relative;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 50px;
    background: black;
    margin-left: 2%;
}

.professor_scenario_edit_menu_main_page_1_middle {
    position: relative;
    width: 100%;
    height: 25%;
    box-sizing: border-box;
    padding: 1% 2.5% 1% 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2%;
}

.professor_scenario_edit_menu_main_page_1_bottom {
    position: relative;
    height: 60%;
    width: 100%;
    box-sizing: border-box;
    padding: 1% 2% 1% 2%;
}

.professor_scenario_edit_menu_main_page_1_bottom_grid {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 20% 80%;
}

.professor_scenario_edit_menu_main_page_1_bottom_left {
    position: relative;
    height: inherit;
    width: 100%;
    display: flex;
    justify-content: center;
}

.image-container {
    position: relative;
    /* display: inline-block; */
    border-radius: 10px;
    max-height: 14vh;
    min-height: 14vh;
    max-width: 100%;
    aspect-ratio: 1/1;
}

.image-container img {
    border-radius: 10px;
    max-height: 100%;
    width: auto;
    display: block;
}


.professor_scenario_edit_menu_main_page_1_bottom_left_info {
    position: relative;
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.professor_scenario_edit_menu_main_page_1_bottom_left_info_desc {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
}

.professor_scenario_edit_menu_main_page_1_bottom_right {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.professor_scenario_edit_menu_activities {
    position: relative;
    width: fit-content;
    height: 90%;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 1%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #E2EEFF;
    gap: 8%;
}

.professor_scenario_edit_menu_activities_number_container {
    position: relative;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.professor_scenario_edit_menu_main_page_3 {
    position: fixed;
    width: 81.5%;
    height: 70%;
    top: 15%;
    border-radius: 10px;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2%;
}

.professor_scenario_edit_menu_main_page_3_top {
    position: relative;
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: repeat(4, 1fr); */
    grid-auto-rows: minmax(auto, auto);
    gap: 0.5rem;
    overflow-y: scroll;
    align-content: start;
    box-sizing: border-box;
    padding-right: 1%;
}

.professor_scenario_edit_menu_main_page_3_top_badge {
    position: relative;
    width: 100%;
    height: 100%;
    /* min-height: 5vh; */
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0.5rem 0.5rem;
    /* margin-bottom: 0.5rem;  */
    margin-right: 0.5rem;
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; */
}

.professor_scenario_edit_menu_top_delete {
    position: absolute;
    width: 4.5vh;
    height: 4.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    top: 20%;
    right: 5%;
    border-radius: 50%;
    background-color: #f0684f;
    box-shadow: 0px 0px 10px 0px #f0684f;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.professor_scenario_edit_menu_main_page_3_bottom {
    position: relative;
    width: 100%;
    height: 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1%;
    /* margin-left: 2.5%; */


}

.professor_scenario_edit_menu_main_page_3_bottom_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.professor_scenario_edit_menu_main_page_3_bottom_spec_comp {
    position: relative;
    height: inherit;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    margin-bottom: 0.8em;
    gap: 1%;
}

.professor_scenario_edit_menu_main_page_3_bottom_key_comp {
    position: relative;
    height: inherit;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    gap: 1%;

}

.professor_scenario_edit_menu_group_item {
    display: "flex";
    width: "100%";
    flex-direction: "row";
    justify-content: "space-between";
    gap: "1vh";
    align-items: "center";
    white-space: "nowrap";
}


.professor_scenario_edit_back_menu_container {
    position: relative;
    height: 35vh;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1%;
    background: #E2EEFF;
    border-radius: 10px;
}

.professor_scenario_edit_back_menu {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1%;
}